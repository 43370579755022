import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import moment from 'moment'
import { memoize } from 'ramda'

import { UILayout, UICalendars, ResponsiveProps, UIIcon } from 'bora-material-ui'

import { required as validateRequired } from '../utils/validate'
import { getStyle, isLiinilaevad } from '../utils/liinilaevadThemeUtils'
import styled from 'styled-components'

@ResponsiveProps()
// eslint-disable-next-line react/prefer-stateless-function
export class CalendarInput extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
    select: PropTypes.func.isRequired,
    getMoreDates: PropTypes.func.isRequired,
    availableDates: PropTypes.object,
    initialVisibleMonth: PropTypes.func,
    boxShadow: PropTypes.any,
  }

  static defaultProps = {
    initialVisibleMonth: () => moment(),
    availableDates: {},
    boxShadow: '0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)',
  }

  getMoreDates = memoize(() => {
    const getDates = () => this.props.getMoreDates()
    return getDates
  })

  select = (date) => {
    const {
      input: { onChange },
    } = this.props

    onChange(date)
    this.props.select(date)
  }

  render() {
    const {
      input: { value },
      availableDates,
      initialVisibleMonth,
      boxShadow,
      showIcon,
    } = this.props

    const style = {
      position: 'absolute',
      top: '-35px',
      left: 'calc(50% - 10px)',
    }

    const DefaultStylesDisabler = styled.span`
      .DayPicker__withBorder {
        box-shadow: none;
      }
      .DayPicker_weekHeader_li {
        font-family: SourceSansPro, Sans-Serif;
        font-weight: 700;
        small {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
      .CalendarMonth_caption {
        font-family: SourceSansPro, Sans-Serif;
        text-transform: capitalize;
        strong {
          font-weight: 400;
        }
      }
      .DayPicker_weekHeader_ul {
        margin: 7px 0px;
      }
      .CalendarMonth_caption {
        padding-bottom: 45px;
      }
      td {
        font-family: SourceSansPro, Sans-Serif;
        font-size: 18px;
      }
    `

    if (isLiinilaevad) {
      return (
        <DefaultStylesDisabler>
          <UILayout
            position={getStyle('relative', 'static')}
            column
            height="324px"
            width="auto"
            overflow={getStyle('visible', 'hidden')}
            boxShadow={boxShadow}
            data-testid="calendar-wrapper"
          >
            {showIcon && (
              <div style={style}>
                <UIIcon height="20px" width="20px" type="icon-calendar" />
              </div>
            )}
            <UICalendars
              selectedValue={value}
              onDateChange={this.select}
              availableDates={availableDates}
              onNextMonthClick={this.getMoreDates}
              initialVisibleMonth={initialVisibleMonth}
            />
          </UILayout>
        </DefaultStylesDisabler>
      )
    }

    return (
      <UILayout
        position={getStyle('relative', 'static')}
        height="324px"
        width="auto"
        overflow={getStyle('visible', 'hidden')}
        boxShadow={boxShadow}
        data-testid="calendar-wrapper"
      >
        {isLiinilaevad && (
          <div style={style}>
            <UIIcon height="20px" width="20px" type="icon-calendar" />
          </div>
        )}
        <UICalendars
          selectedValue={value}
          onDateChange={this.select}
          availableDates={availableDates}
          onNextMonthClick={this.getMoreDates}
          initialVisibleMonth={initialVisibleMonth}
        />
      </UILayout>
    )
  }
}

CalendarInput.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
  media: PropTypes.array,
}

const CalendarField = ({ name, label, validate = [], required, placeholder, ...props }) => {
  const fieldProps = { required }
  const validateFunc = required ? [validateRequired, ...validate] : validate

  return (
    <Field
      name={name}
      type="text"
      label={label}
      component={CalendarInput}
      props={fieldProps}
      placeholder={placeholder}
      validate={validateFunc}
      {...props}
    />
  )
}

CalendarField.defaultProps = {
  label: '',
  placeholder: '',
}

CalendarField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default CalendarField
