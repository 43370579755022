import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { UIIcon, UILayout } from 'bora-material-ui'
import { isLiinilaevad } from '../../utils/liinilaevadThemeUtils'

const Wrapper = styled.div`
  height: 100%;
  white-space: nowrap;

  @media (max-width: 840px) {
    word-break: break-all;
    white-space: normal;
  }
`

const Item = styled.span`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;

  @media (max-width: 840px) {
    word-break: break-all;
    white-space: nowrap;
  }

  & + svg {
    display: inline-block;
    vertical-align: middle;
  }
`

const iconLockStyle = {
  margin: '0 0 0 8px',
  type: 'iconLock',
}

class Locked extends PureComponent {
  static defaultProps = {
    locked: true,
  }

  render() {
    const { children, locked, dataTestId = '' } = this.props

    return (
      <Wrapper>
        <Item>
          {children}
          {isLiinilaevad ? (
            <UILayout style={{ display: 'inline-block', transform: 'translate(0, 1px)' }}>
              <UIIcon display-if={locked} data-testid="locked-locked" {...iconLockStyle} />
            </UILayout>
          ) : (
            <UIIcon display-if={locked} data-testid={dataTestId || 'icon-locked'} {...iconLockStyle} />
          )}
        </Item>
      </Wrapper>
    )
  }
}

export { Locked }
