import React, { useEffect, useState } from 'react'
import { getParameterByName } from './utils/strings'
import { checkTokenValidity } from './services/auth/api'
import { connect } from 'react-redux'
import { actions } from './modules/Auth/provider'
import { LoopCircleLoading } from 'react-loadingg'
import { UILayout } from 'bora-material-ui'

const errorCodes = ['USER_NOT_AUTHORIZED', 'USER_NOT_VALID']

const TokenChecker = ({ children, enabled = false, tokenCheckFailed }) => {
  const token = getParameterByName('hash')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!token || !enabled) return

    setLoading(true)
    checkTokenValidity({ token })
      .catch((error) => {
        const { data = {}, status } = error
        tokenCheckFailed({ ...data, ...(!data.message && { message: 'Token check error' }) })

        if (status === 400 || errorCodes.includes(data.error || '')) {
          setLoading(false)
        }
      })
      .finally(() => setLoading(false))

    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <React.Fragment>
        <UILayout
          bgColor="#F2F2F2"
          xs={{ minWidth: '70px', maxWidth: '70px' }}
          data-testid="token-checker"
          style={{ minHeight: '360px' }}
        >
          <LoopCircleLoading color={window.brandProps.muiTheme.menuItem.color} />
        </UILayout>
      </React.Fragment>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default connect(null, actions)(TokenChecker)
