import { UILayout } from 'bora-material-ui'
import UIElements from '../consts/UIElements'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { THEME } from 'bora-material-ui/themes'

export const AppLayout = (props, { muiTheme }) => {
  const style = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    ...muiTheme.ids.background,
  }

  const {
    location: { pathname = '' },
  } = props

  const getBg = () => {
    const isLL = window.brandProps.theme === THEME.LIINILAEVAD
    const isMainPage = typeof pathname === 'string' && pathname.replaceAll('/', '').length < 3

    if (!isLL) return UIElements.BACKGROUND
    return isMainPage ? UIElements.BACKGROUND : UIElements.BACKGROUND_COLOR
  }

  return (
    <UILayout
      data-testid="app-layout"
      column
      center
      overflow="hidden"
      minHeight="100vh"
      position="relative"
      bg={getBg()}
      style={style}
      className="app-layout-wrapper"
    >
      {props.children}
    </UILayout>
  )
}

AppLayout.contextTypes = {
  muiTheme: PropTypes.object,
}

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
}

export default withRouter(AppLayout)
